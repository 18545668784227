import "@fortawesome/fontawesome-free/css/all.css";
import "musubii/dist/musubii.css";
import "./main.css";

import GoTrue from "gotrue-js";
import {
  Elm
} from "./Main.elm";
// import * as serviceWorker from "./serviceWorker";

const gotrue = new GoTrue({
  APIUrl: "https://shikaq.com/.netlify/identity",
  audience: "",
  setCookie: true
});

const user = gotrue.currentUser();

const getFlugs = (user) =>
  user ? [
    user.email,
    user.user_metadata.full_name,
    user.token ? user.token.access_token : "",
  ] : ["", "", ""];

const app = Elm.Main.init({
  node: document.getElementById("root"),
  flags: getFlugs(user),
});

// elm -> js
app.ports.signIn.subscribe(([mailAddress, password]) => {
  gotrue.login(mailAddress, password, true).then(response => {
    console.log("User logged in");
    app.ports.read.send(getFlugs(response));
  }).catch(error => {
    console.log("failed to login user: %o", error);
    throw error;
  })
});


app.ports.signOut.subscribe((_) => {
  const user = gotrue.currentUser();
  user.logout().then(response => {
      console.log("User logged out");
      app.ports.read.send(getFlugs())
    })
    .catch(error => {
      console.log("Failed to logout user: %o", error);
      throw error;
    });
});


app.ports.refresh.subscribe((_) => {
  // gotrue.currentUser().jwt(true)
  // netlifyIdentity.refresh()
})